
import Vue from "vue";
import BaseBtn from "@/components/base/Button.vue";
import {mapActions} from "vuex";

export default Vue.extend({
  name: 'FilterDialogActions',
  components: {BaseBtn},
  props: {
    hideClearBtn: Boolean
  },
  methods: {

    close() {
      this.$emit('close')
    },
    clear() {
      this.$emit('clear')
    },
  }
})


import Vue, {defineComponent} from "vue";
import FilterField from "@/components/filter/FilterField.vue";
import {mapActions, mapState} from "vuex";
import {Route} from "vue-router";
import Category from "@/components/common/Category.vue";
import BaseBtn from "@/components/base/Button.vue";
import FilterDialog from "@/components/filter/FilterDialog.vue";
import FilterDialogActions from "@/components/filter/FilterDialogActions.vue";
import FliterFieldSkeleton from "@/components/filter/FilterFieldSkeleton.vue";
import FilterFieldSkeleton from "@/components/filter/FilterFieldSkeleton.vue";

export default defineComponent({
  name: 'PageLayout',
  components: {
    FilterFieldSkeleton,
    FliterFieldSkeleton, FilterDialogActions, FilterDialog, BaseBtn, Category, FilterField},
  props: ['taxonomyId', 'loading', 'title'],
  data() {
    return {
      dialog: false,
      showOnly: '',
    }
  },
  computed: {
    ...mapState('filter', {
      fields: 'fields',
    }),
    ...mapState('taxonomy', {
      items: 'items',
    }),
    isUsedFilters(): boolean {
      const query = this.$route.query;
      let hasFilters = false;
      for (const queryKey in query) {
        const value = query[queryKey];

        if (value !== null && value !== '' && value?.length > 0) {
          hasFilters = true;
        }
      }

      return hasFilters;
    }
  },
  methods: {
    ...mapActions('filter', {
      fetchFilter: 'fetch',
      clearFields: 'clearFields'
    }),
    ...mapActions('auth', {
      test: 'test'
    }),
    ...mapActions('taxonomy', {
      fetchTaxonomy: 'fetch'
    }),

    initialize(newRoute?: Route) {
      const route = newRoute || this.$route

      if (this.taxonomyId) {
        this.fetchTaxonomy({uuid: this.taxonomyId});

      } else {
        this.fetchFilter({
          query: {category: route.params.slug},
          values: route.query
        })
      }
    },
    setDialog(state: boolean) {
      this.dialog = state ? state : !this.dialog;
    },
    openCategoryModal() {
      this.setDialog(true);
      this.showOnly = 'categories';
    },
    openFiltersModal() {
      this.setDialog(true);
      this.showOnly = 'filter';
    },
    clearFilters() {
      this.$router.push({});
      this.clearFields();
      this.setDialog(false);
    }
  },
  watch: {
    $route(to, from) {
      if (to.params?.slug !== from.params?.slug) {
        this.setDialog(false);
      }
      this.initialize(to);
    },
    taxonomyId() {
      this.fetchTaxonomy({uuid: this.taxonomyId});
    }
  },
  mounted() {
    this.initialize();
  },
})

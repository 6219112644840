<template>
  <v-skeleton-loader
      v-bind="$attrs"
      type="article"
      tile
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: "FilterFieldSkeleton"
}
</script>

<style scoped>

</style>


import Vue from "vue";
import FilterFactory from "@/components/filter/FilterFactory.vue";
import {mapActions, mapState} from "vuex";
import _ from "lodash";

export default Vue.extend({
  name: 'FilterField',
  props: ['config', 'only'],
  components: {
    FilterFactory
  },
  data() {
    return {
      value: null
    };
  },
  computed: {
    ...mapState('filter', {
      values: 'values'
    })
  },
  methods: {
    ...mapActions('filter', {
      fetch: 'fetch',
      setValue: 'setValue'
    }),
    onInput(value: any) {
      const id = this.config.id;
      const query = _.cloneDeep(this.$route.query);
      this.setValue({key: id, value})

      if(value) {
        query[id] = value;
      } else {
        delete query[id];
      }
      this.$router.push({ query })
    }
  },
  beforeMount() {
    this.value = this.values[this.config.id];
  },
})


import Vue from "vue";
import {FilterModel} from "@/store/models/Filter";

export default Vue.extend({
  name: 'FilterNumber',
  props: {
    config: FilterModel,
    value: Number,
    onInput: Function
  },
})

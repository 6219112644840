
import Vue from "vue";
import {FilterOption} from "@/store/models/Filter";
import {arrayToTree} from "performant-array-to-tree";
import {mapActions} from "vuex";

export default Vue.extend({
  name: 'Category',
  props: ['options'],
  data() {
    return {
      items: [] as any[],
      initiallyOpen: [] as string[],
    }
  },
  methods: {
    ...mapActions('filter', {
      setValue: 'setValue'
    }),
    onClick(slug: string) {
      if (this.$route.params.slug !== slug) {
        this.$router.push('/catalogue/'+slug)
      }
    },
    initialize() {
      if(!this.options.length) return
      const tree = arrayToTree(this.options, {
        dataField: null,
        parentId: 'parentUuid',
        id: 'uuid',
      })

      tree.forEach(option => option.children.sort(
        (fieldA: FilterOption, fieldB: FilterOption) => fieldA.priority - fieldB.priority)
      );

      this.items = tree;
      this.initiallyOpen = [this.items[0].value];
    }
  },
  watch: {
    options: {
      handler: function () {
        this.initialize();
      },
      deep: true
    }
  },
  mounted() {
    this.initialize();
  }
})


import Vue from "vue";
import {FilterModel, FilterOption} from "@/store/models/Filter";
import {arrayToTree} from "performant-array-to-tree";
import {mapActions} from "vuex";
import FilterLabel from "@/components/filter/FilterLabel.vue";

export default Vue.extend({
  name: 'FilterCategory',
  components: {FilterLabel},
  props: {
    config: FilterModel,
    value: String,
    onInput: Function
  },
  computed: {
    items(): any[] {
      const tree = arrayToTree(this.config?.options, {
        dataField: null,
        parentId: 'parentUuid',
        id: 'uuid',
      })

      tree.forEach(option => option.children.sort(
          (fieldA: FilterOption, fieldB: FilterOption) => fieldA.priority - fieldB.priority)
      );

      return [
        {
          children: tree,
          title: 'Всі категорії',
          value: null
        }
      ];
    }
  },
  methods: {
    ...mapActions('filter', {
      setValue: 'setValue'
    }),
    onClick(slug: string) {
      if (slug === null) {
        return this.$router.push('/');
      }
      if (this.$route.params.slug !== slug) {
        return this.$router.push('/catalogue/'+slug);
      }
    },
  },
})


import Vue from "vue";
import {FilterModel} from "@/store/models/Filter";

export default Vue.extend({
  name: 'FilterSelectMultiple',
  props: {
    config: FilterModel,
    value: {
      type: Array,
      default: () => ([]),
    },
    onInput: Function
  }
})

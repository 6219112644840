<template>
  <div>
    <span class="text-h6 font-weight-bold">
      <slot></slot>
    </span>
    <v-divider class="my-3"></v-divider>
  </div>
</template>

<script>
export default {
  name: "FilterLabel"
}
</script>

<style lang="scss" scoped>
@import '../../style/config/variables';
.v-divider {
  border-color: rgba($secondary, .3) !important;
}
</style>


import {Component, defineComponent} from "vue";
import {AttributePresentation} from "@/store/models/Attribute";
import FilterCheckbox from "@/components/filter/FilterUI/FilterCheckbox.vue";
import FilterSelect from "@/components/filter/FilterUI/FilterSelect.vue";
import FilterNumber from "@/components/filter/FilterUI/FilterNumber.vue";
import FilterText from "@/components/filter/FilterUI/FilterText.vue";
import FilterSelectMultiple from "@/components/filter/FilterUI/FilterSelectMultiple.vue";
import FilterRadio from "@/components/filter/FilterUI/FilterRadio.vue";
import {FilterModel} from "@/store/models/Filter";
import FilterCategory from "@/components/filter/FilterUI/FilterCategory.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {mapFields} from "vuex-map-fields";

const fieldComponents: {[key: string]: Component} = {
  [AttributePresentation.text]: FilterText,
  [AttributePresentation.number]: FilterNumber,
  [AttributePresentation.select]: FilterSelect,
  [AttributePresentation.selectMultiple]: FilterSelectMultiple,
  [AttributePresentation.checkbox]: FilterCheckbox,
  [AttributePresentation.radio]: FilterRadio,
}

export default defineComponent({
  name: 'FilterFactory',
  components: {FilterNumber, FilterText, FilterSelect, FilterCheckbox},
  props: {
    config: FilterModel,
    value: [Object, Array, String, Number],
    onInput: Function,
    only: String,
  },
  computed: {
    ...mapFields('filter', {
      values: 'values',
    }),
    show() {
      switch (this.only){
        case 'categories':
          return this.config?.id === 'category'
        case 'filter':
          return this.config?.id !== 'category'
        default:
          return true;
      }
    },
    type() {
      if(!this.config) {
        return 'div'
      } else if (this.config?.id === 'category') {
        return FilterCategory;
      } else {
        return fieldComponents[this.config?.presentation];
      }
    }
  },
})


import Vue from "vue";
import {FilterModel} from "@/store/models/Filter";

export default Vue.extend({
  name: 'FilterRadio',
  props: {
    config: FilterModel,
    value: String,
    onInput: Function
  }
})


import Vue from "vue";
import {FilterModel} from "@/store/models/Filter";
import FilterLabel from "@/components/filter/FilterLabel.vue";

export default Vue.extend({
  name: 'FilterCheckbox',
  components: {FilterLabel},
  props: {
    config: FilterModel,
    value: {
      type: Array,
      default: () => ([]),
    },
    onInput: Function
  },
  watch: {
    $route(to, from) {
      if(to.params.slug !== from.params.slug) {
        this.selected = to.query[this.config?.id] || [];
      }
    },
  },
  data() {
    return {
      selected: [] as any[]
    }
  },
  beforeMount() {
    this.selected = Array.isArray(this.value) ? this.value : [this.value];
  }
})
